import { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import logo from './logo.png';
import gridBg from './gridBg.png'
import './index.css';

// Initialize Supabase client
const [supabaseUrl, supabaseKey] = [process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY];
if (!(supabaseUrl || supabaseKey)) {
  throw new Error('Supabase URL or key not found');
}
const supabase = createClient(supabaseUrl || '', supabaseKey || '');

function App() {
  const [error, setError] = useState<string | null>(null);
  const [showRetryButton, setShowRetryButton] = useState(false);

  const handleLogin = () => {
    setTimeout(() => {
      setShowRetryButton(true)
    }, 1000)
    supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: process.env.REACT_APP_AUTH_REDIRECT
      }
    }).then(({error}) => {
      if (error) setError(error.message);
    })
  }

  useEffect(() => {
    if (!error){
      setTimeout(() => {
        handleLogin()
      }, 600)
    }
  }, [error])


  return (
    <div className="bg-black w-full h-screen flex flex-col items-center justify-center">
      <header className="text-center z-10">
        <img src={gridBg} alt='background' className='absolute inset-0 w-full h-full' />
        <img src={logo} alt='Steer icon' className="mx-auto mb-4 w-40 h-40" />
        <h1 className="text-content-primary text-5xl font-bold my-8 tracking-wide mb-2">
          Sign into Steer
        </h1>
        {
          (!error) && (
            <h2 className='text-content-secondary text-2xl'>
              Redirecting...
            </h2>
          )
        }
        
      </header>


        {error && (
          <div className="flex flex-col items-center space-y-4 z-10">
            <h3 className='text-content-secondary'>
              Google sign-in failed
            </h3>
            <p className="text-window-error mt-4">{error}</p>
            <button 
              className='bg-background-main text-content-primary text-lg font-medium border border-solid border-steerGreen py-2 px-8 cursor-pointer hover:bg-steerGreen hover:text-black'
              onClick={handleLogin}
            >
              Retry
          </button>
          </div>
        )}
      
      {showRetryButton &&
        <div className='z-10 mt-10'>
            <button 
              className='bg-background-main text-content-secondary text-lg font-medium underline cursor-pointer hover:text-white my-4'
              onClick={handleLogin}
            >
              If redirect didn't work, click here
            </button>
        </div>
      }
    </div>
  );
}

export default App;
